import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { graphql } from "gatsby"
import marked from "marked"
import PageLayout from "../components/hbnfpagelayout"
import Head from "../components/head"

export const query = graphql`
  query($slug: String!) {
    contentfulEvents(slug: { eq: $slug }) {
      title
      startDate(formatString: "MMMM D")
      eventEndDate: endDate(formatString: "D, YYYY")
      endDate(formatString: "MMMM D, YYYY")
      eventStartMonth: startDate(formatString: "MMMM")
      eventEndMonth: endDate(formatString: "MMMM")
      displayName
      slug
      signUpForUpdatesUrl
      registrationUrl
      registrationCtaText
      transparentHeader
      headerColor
      summitImage {
        file {
          url
        }
      }
      backgroundImage {
        file {
          url
        }
      }
      bannerLogo {
        file {
          url
        }
      }
      bannerImageLeft {
        file {
          url
        }
      }
      bannerImageRight {
        file {
          url
        }
      }
      bannerImageLeftMobile {
        file {
          url
        }
      }
      bannerImageRightMobile {
        file {
          url
        }
      }
      aboutHeading
      aboutImage {
        file {
          url
        }
      }
      aboutDetails {
        aboutDetails
      }
      speakerDetails {
        status
        statusNote {
          statusNote
        }
        callForSpeakersUrl
        callForSpeakersDetails {
          callForSpeakersDetails
        }
      }
      linksReadMore {
        title
        url
      }
      seoMetadata {
        title
        description {
          description
        }
      }
      venueDetails {
        venueDetails
      }
      bookYourRoomUrl
      conferencePassPricing {
        conferencePassPricing
      }
    }
  }
`

const About = props => {
  let registerUrl
  if (
    typeof window !== "undefined" &&
    props?.data?.contentfulEvents?.registrationUrl
  ) {
    if (window.location.href.includes("?")) {
      let currentLocation = window.location.href.split("?")
      if (props?.data?.contentfulEvents?.registrationUrl?.includes("?")) {
        registerUrl =
          props?.data?.contentfulEvents?.registrationUrl +
          "&" +
          currentLocation[1]
      } else {
        registerUrl =
          props?.data?.contentfulEvents?.registrationUrl +
          "?" +
          currentLocation[1]
      }
    } else {
      registerUrl = props?.data?.contentfulEvents?.registrationUrl
    }
  }
  const [search, setSearch] = useState(() => {
    if (typeof window !== "undefined") return window.location.search
    return ""
  })
  const [url, setPageUrl] = useState(() => {
    if (typeof window !== "undefined") return window.location.href
    return ""
  })

  const [loaded, setLoaded] = useState(false)
  const [scrollPosition, setScrollPosition] = useState(0)
  const [windowWidth, setWindowWidth] = useState(() => {
    if (typeof window !== "undefined") return window.innerWidth
  })
  useEffect(() => {
    sessionStorage.removeItem("activeDate")
    sessionStorage.removeItem("scrollPosition")
    sessionStorage.removeItem("activeGreen")
    sessionStorage.removeItem("activeOrange")
    sessionStorage.removeItem("activePink")
    sessionStorage.removeItem("activePurple")
    sessionStorage.removeItem("activeBlue")
    sessionStorage.removeItem("activeLavender")
    sessionStorage.removeItem("activeRed")
    sessionStorage.removeItem("activeViolet")
    sessionStorage.removeItem("activeLima")
    if (typeof window !== "undefined") {
      setSearch(window.location.search)
      setPageUrl(window.location.href)
      setLoaded(true)
      setWindowWidth(window.innerWidth)
    }
    const close = e => {
      setScrollPosition(window.pageYOffset)
    }
    window.addEventListener("scroll", close)
    window.addEventListener("resize", () => {
      setWindowWidth(window.innerWidth)
    })
    return () => {
      window.removeEventListener("resize", () => {})
    }
  })
  const getMarkdownText = aboutDetails => {
    if (aboutDetails && (aboutDetails !== null || aboutDetails !== undefined)) {
      var rawMarkup = marked(aboutDetails, { sanitize: true, breaks: true })
      return { __html: rawMarkup }
    }
  }

  return (
    <PageLayout
      title={props?.data?.contentfulEvents?.displayName?.toUpperCase()}
      bannerBackground={props?.data?.contentfulEvents?.backgroundImage}
      logo={props?.data?.contentfulEvents?.bannerLogo}
      date={
        props?.data?.contentfulEvents?.startDate === null
          ? props?.data?.contentfulEvents?.endDate
          : props?.data?.contentfulEvents?.eventStartMonth ===
            props?.data?.contentfulEvents?.eventEndMonth
          ? `${props?.data?.contentfulEvents?.startDate} - ${props?.data?.contentfulEvents?.eventEndDate}`
          : `${props?.data?.contentfulEvents?.startDate} - ${props?.data?.contentfulEvents?.endDate}`
      }
      slug={props?.data?.contentfulEvents?.slug}
      signUpUrl={props?.data?.contentfulEvents?.signUpForUpdatesUrl}
      registrationUrl={registerUrl}
      headerbtn={props?.data?.contentfulEvents?.registrationCtaText}
      bannerImageLeft={props?.data?.contentfulEvents?.bannerImageLeft}
      bannerImageLeftMobile={
        props?.data?.contentfulEvents?.bannerImageLeftMobile
      }
      bannerImageRight={props?.data?.contentfulEvents?.bannerImageRight}
      bannerImageRightMobile={
        props?.data?.contentfulEvents?.bannerImageRightMobile
      }
      transparentHeader={props?.data?.contentfulEvents?.transparentHeader}
      scroll={scrollPosition}
    >
      <Head
        title={props?.data?.contentfulEvents?.seoMetadata?.title}
        description={
          props?.data?.contentfulEvents?.seoMetadata?.description?.description
        }
      />
      <div className="about_body">
        {props?.data?.contentfulEvents?.aboutImage && (
          <div className="about_image">
            <img
              src={props?.data?.contentfulEvents?.aboutImage?.file?.url}
              alt="aboutus"
            />
          </div>
        )}
        {props?.data?.contentfulEvents?.aboutHeading && (
          <h2
            className="about_title"
            style={{
              background: `-webkit-linear-gradient(top left, ${props?.data?.contentfulEvents?.headerColor})`,
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              // textAlign: props?.data?.contentfulEvents?.aboutImage
              //   ? ""
              //   : "center",
            }}
          >
            {props?.data?.contentfulEvents?.aboutHeading}
          </h2>
        )}
        <div
          className="about_data"
          // style={{
          //   width:
          //     windowWidth < 1024
          //       ? "100%"
          //       : props?.data?.contentfulEvents?.aboutImage
          //       ? "50%"
          //       : "100%",
          // }}
        >
          <div
            style={{ textAlign: "justify" }}
            dangerouslySetInnerHTML={getMarkdownText(
              props?.data?.contentfulEvents?.aboutDetails?.aboutDetails
            )}
          ></div>
          {props?.data?.contentfulEvents?.speakerDetails?.status === "Open" && (
            <p style={{ whiteSpace: "pre-wrap" }}>
              {
                props?.data?.contentfulEvents?.speakerDetails?.statusNote
                  ?.statusNote
              }
            </p>
          )}
          {props?.data?.contentfulEvents?.speakerDetails?.status === "Open" && (
            <a
              className="call_to_speaker_link"
              target="_blank"
              href={
                props?.data?.contentfulEvents?.speakerDetails
                  ?.callForSpeakersUrl
              }
            >
              SUBMIT YOUR TALK
            </a>
          )}
          {props?.data?.contentfulEvents?.linksReadMore && (
            <>
              <h3>Read more about Kafka Summit:</h3>
              {props?.data?.contentfulEvents?.linksReadMore?.map(
                (item, index) => {
                  return (
                    <a
                      href={item?.url}
                      target="_blank"
                      rel="noreferrer"
                      key={index}
                    >
                      {item?.title}
                    </a>
                  )
                }
              )}
            </>
          )}
          {props?.data?.contentfulEvents?.venueDetails && (
            <div className="venue_block">
              <p className="venue_title">Venue:</p>
              <div
                dangerouslySetInnerHTML={getMarkdownText(
                  props?.data?.contentfulEvents?.venueDetails?.venueDetails
                )}
              ></div>
              {props?.data?.contentfulEvents?.bookYourRoomUrl && (
                <a
                  href={props?.data?.contentfulEvents?.bookYourRoomUrl}
                  target="_blank"
                  rel="noreferrer noopener"
                  style={{ marginTop: "-10px" }}
                >
                  Book Your Room
                </a>
              )}
            </div>
          )}
          {props?.data?.contentfulEvents?.conferencePassPricing && (
            <div className="venue_block">
              <p className="venue_title">Conference Pass Pricing:</p>
              <div
                dangerouslySetInnerHTML={getMarkdownText(
                  props?.data?.contentfulEvents?.conferencePassPricing
                    ?.conferencePassPricing
                )}
              ></div>
            </div>
          )}
          {props?.data?.contentfulEvents?.registrationUrl &&
            props?.data?.contentfulEvents?.registrationCtaText ===
              "Register Today" && (
              <Link
                className="call_to_speaker_link"
                target="_blank"
                to={registerUrl}
              >
                Register Today
              </Link>
            )}
          {props?.data?.contentfulEvents?.signUpForUpdatesUrl &&
            props?.data?.contentfulEvents?.registrationCtaText ===
              "Sign Up For Updates" && (
              <Link
                className="call_to_speaker_link"
                target="_blank"
                to={props?.data?.contentfulEvents?.signUpForUpdatesUrl}
              >
                Sign Up For Updates
              </Link>
            )}
        </div>
      </div>
      <div className="about_featured_image">
        <img src={props?.data?.contentfulEvents?.summitImage?.file?.url} />
      </div>
    </PageLayout>
  )
}
export default About
